<template>
  <div class="c-card position-relative">
    <div class="display">
        <slot name="binder"></slot>
      <img :src="generate_img" class="c-img" alt="">
      <div class="text-wraper">
        <div class="header-style">
          <slot name="header"></slot>
        </div>
        <div class="descr-style">
          <slot name="content"></slot>
        </div>
        <HelpButton class="btn-wrap"
                    :text="btn_text"></HelpButton>
      </div>
    </div>
  </div>
</template>

<script>
import HelpButton from '@/components/HelpButton'
export default {
name: "CardWrapper",
  components:{
    HelpButton
  },
  props:{
    img :{
      type: String
    },
    btn_text :{
      type: String
    },

  },
  computed:{
    generate_img () {
      return require('../assets/' + this.img)
    }
  }
}
</script>

<style lang="scss" scoped>
.display{
  flex-wrap: nowrap !important;
  display: flex !important;
}
.header-style{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 36px;
  letter-spacing: 0;
  line-height: 48px;
}
.descr-style{
  margin-top: 24px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
}
.btn-wrap{
  margin-top: 32px;
}
.text-wraper{
  margin-left: 80px;
}
.c-card{
  width:1200px;
  padding: 56px 80px;
  transform: rotate(360deg);
  border-radius: 20px;
  background-color: #FAFBFD;
  margin: 0 auto;
}
.c-img{
  height: 480px;
  width: 360px;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .c-card{
    padding: 32px 80px 32px 32px;
    width: 640px;
  }
  .text-wraper{
    margin-left: 0;
  }
  .header-style{
    font-size: 30px;
    line-height: 42px;
  }
  .c-img{
    height: 200px;
    width: 157px;
    position: absolute;
    right:32px;
  }
  .descr-style{
    p:first-child{
      width:368px
    }
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .c-card{
    padding: 20px;
    width: 343px;
  }
  .c-img{
    height: 120px;
    width: 94px;
  }
  .text-wraper{
    margin-left: 0;
  }
  .display{
    flex-wrap: unset;
    display: block !important;
  }
  .header-style{
    margin-top: 20px;
    font-size: 24px;
    line-height: 32px;
  }
  .btn-wrap{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .descr-style{
    margin-top: 16px;
  }
  p{
    margin-top:0;
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .c-card{
    padding: 20px;
    width: 288px;
  }
  .c-img{
    height: 120px;
    width: 94px;
  }
  .text-wraper{
    margin-left: 0;
  }
  .display{
    flex-wrap: unset;
    display: block !important;
  }
  .header-style{
    margin-top: 20px;
    font-size: 22px;
    line-height: 30px;
  }
  .btn-wrap{
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .descr-style{
    margin-top: 16px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 27px;
  }
  p{
    margin-top:0;
    margin-bottom: 12px;
  }
}
</style>

<template>
  <div class="pen-wrap d-flex align-items-center justify-content-center">
    <img src="../assets/block_5_circle.png" class="circle" alt="">
    <p class="rpie"> RPIE Penalties </p>
  </div>
</template>

<script>
export default {
  name: "RPIEpenalties"
}
</script>

<style scoped>
.pen-wrap{
  height: 155px;
  position: relative;
  margin: 15px 0 79px;
}
.rpie{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 58px;
  text-align: center;
  margin-bottom: 0;
}
.circle{
  position: absolute;
  height: 152px;
  width: 380px;
  transform: rotate(5deg);
  top: 0;
  left: calc(50% - 190px);
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .circle{
    height: 142px;
    width: 355px;
    transform: rotate(5deg);
    top: 0;
    left: calc(50% - 190px);
  }
  .rpie{
    font-size: 30px;
    line-height: 48px;
  }
  .pen-wrap{
    margin: 15px 0 40px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .circle{
    height: 102px;
    width: 256px;
    transform: rotate(5deg);
    top: 8px;
    left: calc(50% - 128px);
  }
  .rpie{
    font-size: 24px;
    line-height: 40px;
  }
  .pen-wrap{
    margin: 0 0 24px;
    height: 129px;
  }
}
</style>
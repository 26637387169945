<template>
  <div class="position-relative hero-page-wrapper">
    <div class="wrap" id="_wrap">
      <img src="../assets/logo.svg" @click="openSite()" class="logo" alt="">
      <div class="title text-center">
        <img src="../assets/block_5_circle.png" class="circle" alt="">
        <p class="rpie"> RPIE </p>
        <p class="help mb-0"> Compliance and Filing Help! </p>
        <div class="information-block">
          <p class="mb-0 full">Get hands-on assistance in filing RPIE, Storefront Registry,</p>
          <p class="full"> Rent-roll submission or in challenging the RPIE Penalties.</p>
          <p class="mobile">Get hands-on assistance in filing RPIE, Storefront Registry, Rent-roll submission or in challenging the RPIE Penalties.</p>
        </div>
        <HelpButton :text="'Get Help Completing RPIE'"></HelpButton>
      </div>
    </div>
  </div>
</template>

<script>
import HelpButton from '@/components/HelpButton'
export default {
    name: "HeroPage",
  components:{
    HelpButton
  },

    methods: {
      openSite(){
        window.open('https://mgnyconsulting.com')
      }

    }
}
</script>

<style scoped>

.hero-page-wrapper {
  width:  calc(100vw - 15px);
  margin: 0 auto;
}
.full{
  display: block;
}
.mobile{
  display: none;
}
.logo{
  margin: 24px auto;
  display: block;
  cursor: pointer;
}
.circle{
  position: absolute;
  height: 100px;
  width: 220px;
  top: -22px;
  left: calc(50% - 110px);
  transform: rotate(5deg);
}
.wrap{
  background: url('../assets/main block illustration.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 738px;
  position: relative;
  background-position: center;
  background-size: cover;
  position: relative;
}
.title{
  position: relative;
  margin-top: 112px;
  height: 120px;
  font-family: "Open Sans";
  font-size: 52px;
  letter-spacing: -1.13px;
  line-height: 60px;
  text-align: center;
}
.information-block{
  margin-top: 24px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center
}
.rpie{
  color: #4D59EB;
  font-weight: 600
}
.help{
  font-weight: 300;
  color: #0A1250;
}

.main-text span{
  display: inline-block;
  border-bottom: 2px solid #fff;
}


@media screen and (min-width: 1440px) and (max-width: 1919px) {

}

@media screen and (max-width: 1100px) {

}
@media screen and (min-width: 1024px) and (max-width: 1439px) {

}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrap{
    height:  716px;
  }
  .title{
    font-size: 44px;
    margin-top: 91px;
  }
  .circle{
    height: 88px;
    width: 193.6px;
    top: -16px;
    left: calc(50% - 98px);
    transform: rotate(5deg);
  }
  .information-block{
    margin-top: 20px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .logo{
    margin: 20px auto;
    height: 50px;
    width: 56px;
  }
  .title{
    margin-top: 45px;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.7px;
    line-height: 40px;
  }
  .help{
    width: 231px;
    margin: 0 auto;
  }
  .circle{
    height: 62px;
    width: 136.4px;
    top: -14px;
    left: calc(50% - 67px);
    transform: rotate(5deg);
  }
  .rpie{
   margin-bottom: 5px;
  }
  .wrap{
    height: 599px;
    background-position: bottom;
    background-size: contain;
  }
  .information-block{
    width: 306px;
    margin: 20px auto;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    font-weight: 500
  }
  .mobile{
    display: block;
  }
  .full{
    display: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .logo{
    margin: 20px auto;
    height: 50px;
    width: 56px;
  }
  .title{
    margin-top: 45px;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -0.7px;
    line-height: 38px;
  }
  .help{
    width: 231px;
    margin: 0 auto;
  }
  .circle{
    height: 62px;
    width: 136.4px;
    top: -14px;
    left: calc(50% - 67px);
    transform: rotate(5deg);
  }
  .rpie{
    margin-bottom: 5px;
  }
  .wrap{
    height: 526px;
    background-position: bottom;
    background-size: contain;
    padding: 10px;
  }
  .information-block{
    margin: 20px auto;
    line-height: 23px;
  }
  .mobile{
    display: block;
  }
  .full{
    display: none;
  }
  .hero-page-wrapper {
    width:  calc(100vw - 5px);
    margin: 0 auto;
  }
}

</style>

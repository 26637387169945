<template>
    <div class="wrapper">
      <CardWrapper :img="'img_block_1.png'"
                   :btn_text="'Contact an RPIE Expert!'">
        <template v-slot:binder>
          <img src="../assets/block_1_tape.png" class="block-tape" alt="">
        </template>
        <template v-slot:header>
          <p class="mb-0">Real Property Income</p>
          <p >and Expense <span class="blue mb-0">(RPIE)</span></p>
        </template>
        <template v-slot:content>
          <p>
            The NYC Department of Finance (DOF) requires an <span class="blue text-underlined">Annual Real Property
            Income and Expense (RPIE)</span>   filing for most income-producing properties
            with an Assessed Value over $40,000.
          </p>
          <p>
            <b>The deadline to submit RPIE is June 1, 2021. </b>Some properties will require
            only a Claim of Exclusion. Others may require Storefront registration,
            Rent-roll submission, as well as the standard income and expense report.
          </p>
        </template>
      </CardWrapper>
    </div>
</template>

<script>
import CardWrapper from '@/components/CardWrapper'
export default {
name: "RPIE",
  components: {
    CardWrapper,

},

}
</script>

<style scoped>
.block-tape{
  width: 147px;
  position: absolute;
  top: 15px;
  left: 214px;
}
.blue{
  color: #4D59EB
}
.text-underlined{
  text-decoration: underline;
}
.wrapper{
  height: 662px;
  padding: 20px 0 50px
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrapper{
    height: 515px;
    padding: 20px 0 20px;
  }
  .block-tape{
    left: unset;
    width: 100px;
    position: absolute;
    top: 20px;
    right: 8px;
    transform: rotate(13deg);
    z-index: 99;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrapper{
    height: 666px;
    padding: 20px 0 20px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .wrapper{
    height: 718px;
    padding: 16px 0 20px;
    margin-bottom: 16px;
  }
  .block-tape{
    left: 68px;
    width: 71.31px;
    position: absolute;
    top: 12px;
    transform: rotate(13deg);
    z-index: 99;
  }
}
</style>
<template>
  <div class="reqs d-flex align-items-center justify-content-center">
    <div class="wrapper">
      <div class="full">
        <p class="mb-0">Count on MGNY Consulting to provide a </p>
        <p class="mb-0">comprehensive review of your RPIE compliance.  </p>
      </div>
      <div class="mobile">
        <p class="mb-0"> Count on MGNY Consulting </p>
        <p class="mb-0"> to provide a comprehensive </p>
        <p class="mb-0"> review of your RPIE compliance. </p>
      </div>
      <div class="mobilesm">
        <p class="mb-0"> Count on MGNY </p>
        <p class="mb-0"> Consulting to provide a</p>
        <p class="mb-0"> comprehensive  review of  </p>
        <p class="mb-0"> your RPIE compliance.  </p>
      </div>
      <p class="font-blue one-line">We are a Number 1 resource for RPIE filing in NYC. </p>
      <div class="two-lines">
        <p class="font-blue m-0">We are a Number 1 resource</p>
        <p class="font-blue">for RPIE filing in NYC. </p>
      </div>
      <div class="three-lines">
        <p class="font-blue m-0">We are a Number 1 </p>
        <p class="font-blue m-0">resource for RPIE </p>
        <p class="font-blue"> filing in NYC. </p>
      </div>
      <div class="position-relative ">
        <img src="../assets/line_3.png" class="line first one-line" alt="">
        <img src="../assets/line_3.png" class="line second-l two-lines" alt="">
        <img src="../assets/line_3.png" class="line third-l two-lines" alt="">
        <img src="../assets/line_3.png" class="line first three-lines" alt="">
        <img src="../assets/line_3.png" class="line second-l three-lines" alt="">
        <img src="../assets/line_3.png" class="line third-l three-lines" alt="">
      </div>
      <HelpButton class="btn-wrap" width="360px" :text="'RPIE Compliance Help'"></HelpButton>
    </div>
  </div>
</template>

<script>
import HelpButton from '@/components/HelpButton'
export default {
  name: "CountOn",
  components:{
    HelpButton
  },
}
</script>

<style lang="scss" scoped>

.btn-wrap{
  margin-top: 36px;
}
.line{
  position: absolute;
  height: 12px;
  top: -10px;

  &.first{
    width: 895px;
    left: calc(50% - 447px);
  }
  &.second-l{
    width: 442px;
    height: 7px;
    left: calc(50% - 220px);
    top: -70px;
  }
  &.third-l{
    width: 325px;
    height: 8px;
    left: calc(50% - 167px);
    top: -20px;
  }

}
.reqs{
  margin: 100px 0 100px;
}
.wrapper{
  position: relative;
  width: 872px;
  font-family: "Open Sans";
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 58px;
  text-align: center;
  color: #0A1250;

  & > * {
    margin-bottom: 0;
  }
}
.full{
  display: block;
}
.mobile{
  display: none;
}
.mobilesm{
  display: none;
}
.font-bold{
  font-weight: 600;
}
.font-blue{
  font-weight: 600;
  color: #4D59EB;
}
.two-lines{
  display: none;
}
.three-lines{
  display: none;
}
.one-line{
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .two-lines{
    display: block;
  }
  .three-lines{
    display: none;
  }
  .one-line{
    display: none;
  }
  .wrapper{
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 48px;
  }
  .reqs{
    margin: 100px 0 70px;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .two-lines{
    display: block;
  }
  .three-lines{
    display: none;
  }
  .one-line{
    display: none;
  }
  .wrapper{
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 40px;
    padding: 16px;
  }
  .reqs{
    margin: 56px 0 56px;
  }
  .line{
  &.second-l{
    top: -63px!important;
    width: 341.12px;
    height: 7px;
    left: calc(50% - 170px);
  }
    &.third-l{
      top: -22px!important;
      width: 248.12px;
      height: 7px;
      left: calc(50% - 125px);
    }
  }
  .mobile{
    display: block;
  }
  .full{
    display: none;
  }
  .mobilesm{
    display: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .reqs{
    margin: 40px 0 40px;
  }
  .two-lines{
    display: none;
  }
  .three-lines{
    display: block;
  }
  .one-line{
    display: none;
  }
  .line{
    &.first{
      top: -99px!important;
      width: 221.12px;
      height: 7px;
      left: calc(50% - 110px);
    }
    &.second-l{
      top: -63px!important;
      width: 211.12px;
      height: 7px;
      left: calc(50% - 105px);
    }
    &.third-l{
      top: -22px!important;
      width: 141.12px;
      height: 7px;
      left: calc(50% - 71px);
    }
  }
  .wrapper{
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 37px;
    padding: 16px;
  }
  .mobile{
    display: none;
  }
  .full{
    display: none;
  }
  .mobilesm{
    display: block;
  }

}
</style>
<template>
    <div class="wrapper">

      <CardWrapper :img="'block_3_img.png'"
                   :btn_text="'Get Help Completing RPIE'">
        <template v-slot:binder>
          <img src="../assets/block_3_binder.png" class="block-tape" alt="">
        </template>
        <template v-slot:header>
          <p class="mb-0"><span class="blue">Commercial Rent Roll</span> </p>
        </template>
        <template v-slot:content>
          <p class="mb-1">
            Commercial Rent Rolls must be submitted in the format prescribed by the
            Finance Department. The rent roll information must include details about
            each commercial tenant, like:
          </p>
          <p class="mb-1">  -  Occupancy status </p>
          <p class="mb-1">  -  Details on vacancies </p>
          <p class="mb-1">  -  Tenants' business activities </p>
          <p class="mb-1">  -  Lease terms </p>
          <p class="mb-1">  -  Latest rent amounts </p>


        </template>
      </CardWrapper>
    </div>
</template>

<script>
import CardWrapper from '@/components/CardWrapper'
export default {
name: "Storefront",
  components: {
    CardWrapper,

},

}
</script>

<style scoped>
.block-tape{
  width: 79px;
  position: absolute;
  top: 10px;
  left: 228px;
}
.blue{
  color: #4D59EB
}
.text-underlined{
  text-decoration: underline;
}
.wrapper{
  height: 608px;
  margin-bottom: 100px;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrapper{
    height: 516px;
    padding: 0 62px 20px;
    margin-bottom: 0;
  }
  .block-tape{
    left: unset;
    width: 43px;
    position: absolute;
    top: 5px;
    right: 79px;
    z-index: 99;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrapper{
    height: 628px;
    padding: 20px 0 0;
    margin-bottom: 31px;
  }
  .block-tape{
    left: 59px;
    width: 25px;
    position: absolute;
    top: 7px;
    z-index: 99;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .wrapper{
    height: 655px;
    padding: 20px 0 0;
    margin-bottom: 16px;
  }
  .block-tape{
    left: 59px;
    width: 25px;
    position: absolute;
    top: 7px;
    z-index: 99;
  }
}
</style>
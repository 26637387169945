<template>
    <div class="wrapper">

      <CardWrapper :img="'block_4_img.png'"
                   :btn_text="'Get Help Completing RPIE'">
        <template v-slot:binder>
          <img src="../assets/block_4l_ove_NY.png" class="block-tape" alt="">
        </template>
        <template v-slot:header>
          <p class="mb-0"> <span class="blue">Residential Rent Roll</span> </p>
        </template>
        <template v-slot:content>
          <p class="mb-1">
            Residential Rent Rolls must be submitted in the format prescribed by the
            Finance Department. The rent roll information must include details about
            each commercial tenant, like:
          </p>
          <p class="mb-1"> -  Rent regulation status </p>
          <p class="mb-1"> -  Unit description </p>
          <p class="mb-1"> -  Rent regulation status </p>
          <p class="mb-1"> -  Occupancy / Vacancy status </p>
          <p class="mb-1"> -  Lease terms </p>
          <p class="mb-1"> -  Latest rent amounts for each apartment </p>
        </template>
      </CardWrapper>
    </div>
</template>

<script>
import CardWrapper from '@/components/CardWrapper'
export default {
name: "Storefront",
  components: {
    CardWrapper,

},

}
</script>

<style scoped>
.block-tape{
  width: 212px;
  position: absolute;
  top: 34px;
  left: 32px;
}
.blue{
  color: #4D59EB
}
.text-underlined{
  text-decoration: underline;
}
.wrapper{
  height: 586px;
  padding-bottom:  50px;
  margin-bottom: 67px;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrapper{
    height: 570px;
    padding: 20px 62px 20px;
    margin-bottom: 0;
  }
  .block-tape{
    width: 100px;
    transform: rotate(-15deg);
    position: absolute;
    top: 20px;
    left: unset;
    right: 116px;
    z-index: 99;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrapper{
    height: 666px;
    padding: 0;
    margin-bottom: 22px;
  }
  .block-tape{
    left: 64px;
    width: 88px;
    position: absolute;
    top: 10px;
    transform: rotate(47deg);
    z-index: 99;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .wrapper{
    height: 699px;
    padding: 0;
    margin-bottom: 10px;
  }
  .block-tape{
    left: 64px;
    width: 88px;
    position: absolute;
    top: 10px;
    transform: rotate(47deg);
    z-index: 99;
  }
}
</style>
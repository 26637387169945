<template>
    <div class="trusted">
      <p class="text">Trusted by NYC’s Finest Companies </p>
      <div class="justify-content-around full">
        <img src="../assets/logo_1.png" class="logo1" alt="">
        <img src="../assets/logo_2.png" class="logo2" alt="">
        <img src="../assets/logo_5.png" class="logo3" alt="">
        <img src="../assets/logo_4.png" class="logo4" alt="">
        <img src="../assets/logo_3.png" class="logo5" alt="">
      </div>
      <div class="justify-content-around mobile">
        <img src="../assets/logo_2.png" class="logo2" alt="">
        <img src="../assets/logo_5.png" class="logo3" alt="">
        <img src="../assets/logo_4.png" class="logo4" alt="">
      </div>
      <div class="justify-content-around mobile second-line">
        <img src="../assets/logo_1.png" class="logo1" alt="">
        <img src="../assets/logo_3.png" class="logo5" alt="">
      </div>
    </div>
</template>

<script>
export default {
name: "Trusted"
}
</script>

<style scoped>
.logo1{
  height: 40px;
  width: 75px;
}
.logo2{
  height: 48px;
  width: 48px;
}
.logo3{
  height: 44px;
  width: 171px;
}
.logo4{
  height: 48px;
  width: 48px
}
.logo5{
  height: 44px;
  width: 122px;
}
.trusted{
  padding-top: 80px;
  height: 263px;
}
.text{
  height: 27px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  margin-bottom: 34px;
}
.full{
  display: flex;
}
.mobile{
  display: none;
}
.second-line{
  margin: 32px auto;
  width: 300px
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .trusted{
    padding-top: 56px;
    height: 213px;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .trusted{
    padding-top: 40px;
    height: 251px;
  }
  .mobile{
    display: flex;
  }
  .full{
    display: none;
  }
  .logo1{
    height: 40px;
    width: 68px;
  }
  .logo2{
    height: 40px;
    width: 40px;
  }
  .logo3{
    height: 40px;
    width: 155px;
  }
  .logo4{
    height: 40px;
    width: 40px
  }
  .logo5{
    height: 40px;
    width: 111px;
  }
}
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .trusted{
      padding-top: 32px;
      height: 240px;
    }
    .mobile{
      display: flex;
    }
    .full{
      display: none;
    }
    .logo1{
      height: 40px;
      width: 68px;
    }
    .logo2{
      height: 40px;
      width: 40px;
    }
    .logo3{
      height: 40px;
      width: 155px;
    }
    .logo4{
      height: 40px;
      width: 40px
    }
    .logo5{
      height: 40px;
      width: 111px;
    }

}
</style>
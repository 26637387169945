<template>
  <div id="app ">
    <HeroPage  class="bg-color-white"></HeroPage>
    <div  class="container-width">
      <Trusted class="bg-color-white"></Trusted>
      <RPIE  class="bg-color-white"></RPIE>
      <Storefront  class="bg-color-white"></Storefront>
      <RRRequirements class="bg-color-white"></RRRequirements>
      <CommRentRR  class="bg-color-white"></CommRentRR>
      <ResRR  class="bg-color-white"></ResRR>
      <RPIEpenalties class="bg-color-white"></RPIEpenalties>
      <PenaltiesContesting></PenaltiesContesting>
      <CountOn  class="bg-color-white"></CountOn>
      <Footer class="bg-color-white"></Footer>
    </div>

    <SignUpModal></SignUpModal>
  </div>
</template>

<script>
import HeroPage from '@/views/HeroPage'
import Trusted from '@/views/Trusted'
import RPIE from '@/views/RPIE'
import Storefront from '@/views/Storefront'
import RRRequirements from '@/views/RRRequirements'
import CommRentRR from '@/views/CommRentRR'
import ResRR from '@/views/ResRR'
import RPIEpenalties from '@/views/RPIEpenalties'
import PenaltiesContesting from '@/views/PenaltiesContesting'
import CountOn from '@/views/CountOn'
import Footer from '@/components/Footer'

import SignUpModal from '@/views/SignUpModal.vue'

import VueMeta from 'vue-meta'
import Vue from 'vue'
Vue.use(VueMeta)


export default {
  name: 'App',
  metaInfo() {
    return {
      meta: [
        { name: 'facebook-domain-verification', content:  't1bwny25njn2g63h2p57mnewb826gr'},
      ]
    }
  },

  components: {
    HeroPage,
    Trusted,
    RPIE,
    Storefront,
    RRRequirements,
    CommRentRR,
    ResRR,
    RPIEpenalties,
    PenaltiesContesting,
    Footer,
    CountOn,


    SignUpModal,
  }
}
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

.container-width{
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.bg-color-white {
  background-color: rgb(255, 255, 255);
}
//@media  (min-width:1520px)  {
//  .container-width {
//    width: 1420px;
//  }
//}
@media  (min-width:1441px)  {
  .container-width {
    width: 1440px;
  }
}
@media  (max-width:1439px) and (min-width:1024px)  {
  .container-width {
    width: 100%;
  }
}
@media  (max-width:1023px) and (min-width:760px)  {
  .container-width {
    width: 100%;
    padding: 0 2px;
  }
}
@media  (max-width:760px) and (min-width:320px)  {
  .container-width {
    width: 100%;
    padding: 0 1px;
  }
}


@font-face {
  font-family: "Integral CF ExtraBold";
  src: local("Integral CF"),   url('./fonts/IntegralCF-ExtraBold.woff2')  format("woff2");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Regular.ttf')   format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-SemiBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Bold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Open Sans";
  src:   url('./fonts/OpenSans-Light.ttf')   format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans-ExtraBold";
  src:   url('./fonts/OpenSans-ExtraBold.ttf')   format('truetype');
  font-style: normal;
  font-weight: 800;
}

$grid-breakpoints: (
  xs: 0,
  sm: 376px,
  md: 769px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px
);

$container-max-widths: (
sm: 374px,
md: 732px,
lg: 976px,
xl: 1380px,
xxl: 1500px
);

// Import BT sources
@import "../node_modules/bootstrap/scss/bootstrap";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

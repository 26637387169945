<template>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-xl-6  col-md-12">
      <div class="c-card dangercrd red">
        <img src="../assets/block_6_red_pin.png" class="block-tape" alt="">
        <div class="head">
          <p class="warn mb-0">Penalties <span class="danger">for</span>  </p>
          <p class="mb-0">Non-Filing or Late Filing </p>
        </div>
        <div class="descr">
          <p>A prudent owner should approach the RPIE process with
            the level of diligence it deserves because the RPIE is the
            first step toward a fair tax assessment.
          </p>
          <p>With its ever-increasing grip on RPIE compliance, the City
            has made it abundantly clear that penalties for non-filing
            or late filing can be severe.
          </p>
          <p>Not only are there monetary fines, there are also a number
            of other negative consequences for non-filing or late filing.
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-xl-6  col-md-12">
      <div class="c-card green">
        <img src="../assets/block_2_green_pin.png" class="block-tape grpin" alt="">
        <div class="head">
          <p class="success mb-0">Contesting </p>
          <p class="mb-0">RPIE Penalties </p>
        </div>
        <div class="descr descrgreen">
          <p>Outstanding RPIE penalties have proven to be a significant
            problem for both current owners and prospective purchasers.
            MGNY has helped identify relevant defenses in many scenarios
            and has contested improperly assessed RPIE penalties.
          </p>
          <p>Even in cases in which the penalty is already paid, MGNY has
            successfully obtained refunds for overpayment.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "PenaltiesContesting"
}
</script>


<style lang="scss" scoped>

.descr{
  width: 398px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  &.descrgreen{
    width: 444px;
  }
}
.warn{
  color: #EB4D4D;
}
.danger{
  color: #FF3A45;
}
.success{
  color: #4FBF1E;
}
.head{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 36px;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 24px;
}
.dangercrd{
  float: right;
}
.c-card{
  padding: 78px 115px 120px 56px;
  height: 560px;
  width: 588px;
  transform: rotate(360deg);
  border-radius: 20px;

  &.red{
    background-color: #FFF5F4;
   }
  &.green{
    background-color: #F5FAF2;
   }
}
.block-tape{
  position: absolute;
  right: 184px;
  top: -21px;
  height: 74px;
}
.grpin{
  moz-transform: scale(-1, 1) rotate(-11deg);
  -o-transform: scale(-1, 1) rotate(-11deg);
  -webkit-transform: scale(-1, 1) rotate(-11deg);
  transform: scale(-1, 1) rotate(-11deg);
  right: 266px;
}
@media screen and (min-width: 768px) and (max-width: 1440px) {
.block-tape{
  position: absolute;
  right: 63px;
  top:23px;
  height: 70px;
}
  .grpin{
    moz-transform: scale(-1, 1) rotate(-11deg);
    -o-transform: scale(-1, 1) rotate(-11deg);
    -webkit-transform: scale(-1, 1) rotate(-11deg);
    transform: scale(-1, 1) rotate(-11deg);
  }
  .c-card{
    padding: 32px 106px 32px 32px;
    margin: 0 auto;
    height: 380px;
    width: 640px;

  }
  .descr{
    font-size: 15px;
    letter-spacing: 0;
    line-height: 30px;
    width:502px
  }
  .head{
    font-size: 30px;
    letter-spacing: 0;
    line-height: 42px;
  }
  .dangercrd{
    float: none;
    margin-bottom: 40px;
    height: 422px;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .block-tape{
    right: 35px;
    top: -26px;
    height: 58px;
  }
  .grpin{
    top: -6px;
    moz-transform: scale(-1, 1) rotate(-11deg);
    -o-transform: scale(-1, 1) rotate(-11deg);
    -webkit-transform: scale(-1, 1) rotate(-11deg);
    transform: scale(-1, 1) rotate(-11deg);
  }
  .c-card{
    padding:20px;
    margin: 0 auto;
    height: 444px;
    width: 343px;

  }
  .descr{
    font-size: 15px;
    letter-spacing: 0;
    line-height: 30px;
    width:297px!important;
  }
  .head{
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
  }
  .dangercrd{
    float: none;
    margin-bottom: 40px;
    height: 488px;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .block-tape{
    right: 35px;
    top: -26px;
    height: 58px;
  }
  .grpin{
    top: -6px;
    moz-transform: scale(-1, 1) rotate(-11deg);
    -o-transform: scale(-1, 1) rotate(-11deg);
    -webkit-transform: scale(-1, 1) rotate(-11deg);
    transform: scale(-1, 1) rotate(-11deg);
  }
  .c-card{
    padding:16px;
    margin: 0 auto;
    height: 406px;
    width: 288px;

  }
  .descr{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 27px;
    width:244px!important;
    &>p{
      margin-bottom: 12px;
    }
  }
  .head{
    font-size: 22px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 16px;
  }
  .dangercrd{
    float: none;
    margin-bottom: 40px;
    height: 487px;
  }
}
</style>
<template>
    <div class="wrapper">

      <CardWrapper :img="'block_2_img.png'"
                   :btn_text="'Get Help with Storefronts'">
        <template v-slot:binder>
          <img src="../assets/block_2_green_pin.png" class="block-tape" alt="">
        </template>
        <template v-slot:header>
          <p class="mb-0">Storefront Registry </p>
          <p><span class="blue mb-0">New Requirement</span><p/>
        </template>
        <template v-slot:content>
          <p>
            Local Law 157 requires the DOF to implement the new Storefront Registry
            requirement. The Storefront Registry is applicable to owners of ground-floor
            or second-floor commercial premises that are visible from the street and
            accessible to the public directly from the street or from the interior of a building.
          </p>
          <p>
            To avoid penalties, an annual registration statement must be provided including:
            lease information, rent increases, monthly rent per square foot, vacancies,
            owner-occupancy, and tenant information for the reporting period.
          </p>
        </template>
      </CardWrapper>
    </div>
</template>

<script>
import CardWrapper from '@/components/CardWrapper'
export default {
name: "Storefront",
  components: {
    CardWrapper,

},

}
</script>

<style scoped>
.block-tape{
  width: 48px;
  position: absolute;
  top: 29px;
  left: 347px;
}
.blue{
  color: #4D59EB
}
.text-underlined{
  text-decoration: underline;
}
.wrapper{
  height: 662px;
  padding: 50px 0
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrapper{
    height: 604px;
    padding: 20px 62px 20px;
  }
  .block-tape{
    left: unset;
    height: 40px;
    width: 27.03px;
    position: absolute;
    top: 14px;
    right: 54px;
    transform: rotate(13deg);
    z-index: 99;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrapper{
    height: 772px;
    padding: 20px 0 20px;
  }
  .block-tape{
    left: 94px;
    width: 22px;
    position: absolute;
    top: 8px;
    transform: rotate(13deg);

  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .wrapper{
    height: 888px;
    padding: 20px 0 20px;
  }
  .block-tape{
    left: 94px;
    width: 22px;
    position: absolute;
    top: 8px;
    transform: rotate(13deg);

  }
}
</style>
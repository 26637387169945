<template>
  <div class="reqs d-flex align-items-center justify-content-center">
    <div class="wrapper">
      <p class="font-bold">Rent Roll Requirements</p>
      <p >Properties having an Actual Assessed Value of  </p>
      <div class="full">
        <p class="font-blue">$750,000 or greater will be required to file   </p>
        <div class="position-relative">
          <img src="../assets/line_1.png" class="line first" alt="">
        </div>
        <p class="font-blue">an addendum containing Rent Roll.   </p>
        <div class="position-relative">
          <img src="../assets/line_2.png" class="line second" alt="">
        </div>
      </div>
      <div class="mobile">
        <p class="font-blue">$750,000 or greater will be   </p>
        <div class="position-relative">
          <img src="../assets/line_1.png" class="line firstm" alt="">
        </div>
        <p class="font-blue"> required to file an addendum </p>
        <div class="position-relative">
          <img src="../assets/line_1.png" class="line secondm" alt="">
        </div>
        <p class="font-blue">containing Rent Roll.   </p>
        <div class="position-relative">
          <img src="../assets/line_2.png" class="line thirdm" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RRRequirements"
}
</script>

<style lang="scss" scoped>

.full{
  display: block;
}
.mobile{
  display: none;
}
.line{
  position: absolute;
  height: 12px;
  top: -10px;

  &.first{
    width: 725px;
    left: calc(50% - 362px);
  }
  &.second{
    width: 585px;
    left: calc(50% - 292px);
  }
}
.reqs{
  margin: 100px 0 108px;
}
.wrapper{
  position: relative;
  width: 758px;
  font-family: "Open Sans";
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 58px;
  text-align: center;
  color: #0A1250;

  & > * {
    margin-bottom: 0;
  }
}
.font-bold{
  font-weight: 600;
}
.font-blue{
  font-weight: 300;
  color: #4D59EB;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .reqs{
    margin: 60px 0 86px;
  }
  .wrapper{
    font-size: 30px;
    line-height: 48px;

    & > * {
      margin-bottom: 0;
    }
  }
  .line{
    &.first{
      width: 642px;
      height:7px;
      left: calc(50% - 321px);
    }
    &.second{
      width: 495px;
      height:7px;
      left: calc(50% - 248px);
    }
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrapper{
    font-size: 24px;
    line-height: 40px;

    & > * {
      margin-bottom: 0;
    }
  }
  .reqs{
    margin: 50px 0 56px;
  }
  .mobile{
    display: block;
  }
  .full{
    display: none;
  }
  .line{
    height: 5px;
    top: -5px;
    &.firstm{
      width: 301px;
      left: calc(50% - 150px);
    }
    &.secondm{
      width: 322px;
      left: calc(50% - 161px);
    }
    &.thirdm{
      width: 222px;
      left: calc(50% - 111px);
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .reqs{
    margin: 48px 0 47px;
  }
  .wrapper{
    font-size: 22px;
    line-height: 37px;

    & > * {
      margin-bottom: 0;
    }
  }
  .mobile{
    display: block;
  }
  .full{
    display: none;
  }
  .line{
    height: 5px;
    top: -5px;
    &.firstm{
      width: 301px;
      left: calc(50% - 150px);
    }
    &.secondm{
      width: 302px;
      left: calc(50% - 151px);
    }
    &.thirdm{
      width: 222px;
      left: calc(50% - 111px);
    }
  }
}
</style>
<template>
<div class="wrapper">
  <hr class="hr">
  <div class="d-flex justify-content-between">
    <div class="full">
      <img src="../assets/logo.svg" @click="openSite()" class="logo" alt="">
      <div class="devider"></div>
      <div>
        <p class="text head">MGNY CONSULTING</p>
        <p class="text">18 Bridge St. Suite 2D</p>
        <p class="text"> Brooklyn, NY 11201</p>
        <p class="text">T: 718 - 522 - 1111</p>
      </div>
    </div>
    <div class="full flex-column">
      <div class="text">©2021 MGNY Consulting</div>
      <div class="text">All rights reserved</div>
    </div>
    <div class="smll">
      <img src="../assets/logo.svg" @click="openSite()" class="logo" alt="">
      <div class="text mt-sml">©2021 MGNY Consulting</div>
    </div>
    <div class="smll">
      <div>
        <p class="text head">MGNY CONSULTING</p>
        <p class="text">18 Bridge St. Suite 2D</p>
        <p class="text"> Brooklyn, NY 11201</p>
        <p class="text">T: 718 - 522 - 1111</p>
      </div>
      <div class="text mt-sml">All rights reserved</div>
    </div>

  </div>
</div>
</template>

<script>
export default {
name: "Footer",
  methods:{
    openSite(){
      window.open('https://mgnyconsulting.com')
    }
  }
}
</script>

<style scoped>
.mt-sml{
  margin: 32px 0 17px;
}
.logo{
  cursor: pointer;
}
.full{
  display: flex;
}
.smll{
  display: none;
}
.head{
  height: 12px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 9px;
  font-weight: bold;
  letter-spacing: -0.23px;
  line-height: 12px;
}
.text{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 9px;
  letter-spacing: -0.23px;
  line-height: 12px;
  margin-bottom: 4px;
}
.devider{
  margin: 0 10px;
  border-right: 1px solid  #0A1250;
}
.hr{
  margin-bottom: 28px;
}
.wrapper{
  padding: 0 120px;
  height: 150px;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrapper{
    padding: 0 48px;
    height: 100px;
  }
  .smll{
    display: none;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .wrapper{
    padding: 0 16px;
    height: 100px;
  }
  .smll{
    display: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .wrapper{
    padding: 0 16px;
    height: 100px;
  }
  .devider{
    display: none;
  }
  .full{
    display: none ;
  }
  .smll{
    display: block;
  }
}
</style>